import React, {useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {withStyles} from '@material-ui/styles'
import {
  Paper,
  Button,
  Avatar,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import _ from 'lodash'
import cx from 'classnames'
import * as Actions from './redux/actions'
import * as HeaderActions from '../common/redux/actions.header'
import * as NotificationActions from '../common/redux/actions.notifications'
import {ChoiceTypography, styles} from './styles/CourseItem.choice.styles.js'

function CourseItemChoice({
  classes,
  item,
  hasMinDurationPassed,
  onCourseItemComplete,
  create,
  isCompletingItem,
}) {
  const [currentIndex, setCurrentIndex] = useState(0)
  const itemValue = item?.choices?.[currentIndex]
  // const itemValue = _.first(item?.choices)
  const [choice, setChoice] = useState('')

  const handleChange = event => {
    setChoice(event.target.value)
  }

  return (
    <div className={classes.root}>
      <div className={classes.mainCont}>
        <Paper className={classes.textCont}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ChoiceTypography>{itemValue?.question || ''}</ChoiceTypography>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="question-choice"
                  name="customized-radios"
                  onChange={handleChange}
                >
                  {itemValue.options.map((option, index) => {
                    if (!option) {
                      console.warn(`Invalid option at index ${index}`, option)
                      return null
                    }
                    return (
                      <FormControlLabel
                        className={classes.choicesControl}
                        value={option}
                        key={`question-choice-${index}`}
                        control={
                          <Radio
                            color="primary"
                            checked={option.id.toString() === choice}
                            id={`question-choice-${option.id}`}
                            className={classes.radioRoot}
                            icon={
                              <Avatar className={classes.radioIcon}>
                                {String.fromCharCode(97 + index).toUpperCase()}
                              </Avatar>
                            }
                            checkedIcon={
                              <Avatar className={cx(classes.radioIcon, classes.radioCheckedIcon)}>
                                {String.fromCharCode(97 + index).toUpperCase()}
                              </Avatar>
                            }
                          />
                        }
                        label={option.label}
                      />
                    )
                  })}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
        <div className={hasMinDurationPassed ? classes.nextButton : classes.nextButtonDisabled}>
          <Button
            disabled={!hasMinDurationPassed || isCompletingItem}
            onClick={() => {
              if (choice === '') {
                create('warning', 'You must select an option to continue.')
                return
              }
              onCourseItemComplete(item, parseInt(choice, 10))
              setCurrentIndex(prevIndex => prevIndex + 1)
              setChoice('')
            }}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  )
}

CourseItemChoice.displayName = 'CourseItemChoice'

function mapStateToProps(state) {
  return {
    courses: _.get(state, 'course'),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions, ...HeaderActions, ...NotificationActions}, dispatch)
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles, {withTheme: true}),
)(CourseItemChoice)
